import React from 'react';

import { DevtoolsWrapper } from '@/components';
import { TimeProvider } from '@/contexts';
import { TimePicker } from './TimePicker';

const Example = () => {
  return (
    <DevtoolsWrapper>
      <TimeProvider>
        <TimePicker />
      </TimeProvider>
    </DevtoolsWrapper>
  );
};

export default Example;
